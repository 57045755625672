import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Bar, Line, Pie, PolarArea } from 'react-chartjs-2'

function ChartAdmin({ data_gra, meses ,graph_title}) {

    var dataGraph = []
    var i = 1
    for (let x of data_gra) {
        dataGraph.push(                        
            {
            id: i,
            label: x.title,
            data: x.data,
            backgroundColor: x.backg,
            borderColor: x.backg,
            tension: 0.5,
            pointRadius: 5,
            fill: true

        })

        i = i + 1
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop:'30px'}}>
            <Grid>
            <Typography variant='h5' sx={{textAlign:"center",fontFamily:"inherit",marginBottom:'20px' }}>{graph_title}</Typography>

            </Grid>
            <Bar
                datasetIdKey='id'
                data={{
                    labels: meses,
                    datasets:dataGraph,
                    
                }}
            />
        </Grid>
    )
}

export default ChartAdmin